import { makeStyles } from '@mui/styles'
import { styled } from 'styled-components'
import palette from '../../../../utils/Theme/palette'
import { theme } from 'styles/Theme/theme'

const moreImageContainer = (size, borderColor) => ({
  height: size,
  width: size,
  border: '1px solid',
  borderColor: borderColor,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
})

const useStyles = makeStyles({
  breadCrumbs: {
    backgroundColor: palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 25,
    paddingTop: 20,
    paddingBottom: 20,
  },
  detailsContainer: {
    backgroundColor: '#f9f9f9',
    position: 'relative',
    paddingBottom: '6rem',
  },

  productImg: {
    maxHeight: 400,
    margin: 'auto',
    maxWidth: 500,
    borderRadius: 12,
  },
  moreImagesContainer: {
    display: 'flex',
    justifyContent: 'center',
    overflowX: 'auto',
    flexWrap: 'wrap',
    gap: '10px',
  },
  moreImages: {
    ...moreImageContainer(90, 'lightgrey'),
    borderRadius: 12,
    padding: 6,
    backgroundColor: '#ffffff',
  },
  greyContainer: {
    ...moreImageContainer('100%', '#e7e7e7'),
    backgroundColor: '#e7e7e7',
    borderRadius: 6,
  },
  moreImage: {
    height: 80,
    objectFit: 'contain',
  },
  productCard: {
    marginTop: 40,
    padding: '12px 16px',
    display: 'flex',
    flexDirection: 'column',
  },
  sizeContainer: {
    height: 38,
    width: 38,
    borderRadius: 9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20,
    cursor: 'pointer',
    border: '1px solid #BEBCBD',
  },
  activeSizeContainer: {
    height: 38,
    width: 38,
    borderRadius: 9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20,
    backgroundColor: '#008ECC',
    cursor: 'pointer',
  },
  availableColors: {
    ...moreImageContainer(75, 'lightgrey'),
    marginRight: 12,
    borderRadius: 12,
    padding: 6,
    backgroundColor: '#ffffff',
    minWidth: 75,
  },
  availableColorImg: { height: 55, objectFit: 'contain' },

  productDetailsSection: {
    backgroundColor: palette.background.paper,
    padding: '24px',
    overflow: 'auto',
  },
  keyValueContainer: {
    marginTop: '10px',
    gap: '30px',
  },
  key: {
    textTransform: 'capitalize',
    fontWeight: 500,
    fontFamily: 'Inter !important',
  },
  value: {
    fontWeight: 500,
  },
  outOfStock: {
    margin: '12px 0 0px 0',
    padding: '6px',
    backgroundColor: '#FC95953D',
    borderRadius: 8,
  },
  customization: {
    border: '2px solid rgba(190, 188, 189, 0.46);',
    borderRadius: 9,
    marginRight: 8,
    padding: '6px 12px',
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: 8,
    position: 'relative',
    textTransform: 'capitalize',
  },
  selectedCustomization: {
    textTransform: 'capitalize',
    border: '2px solid #B5CAD1',
    background: '#E7EEF0',
    borderRadius: 9,
    marginRight: 8,
    padding: '6px 12px',
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: 8,
    position: 'relative',
  },
  cross: {
    position: 'absolute',
    top: 2,
    right: 3,
    fontSize: '12px !important',
  },
  square: {
    backgroundColor: 'transparent',
    border: '1px solid #008001',
    width: 18,
    height: 19,
    marginRight: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circle: {
    backgroundColor: '#008001',
    borderRadius: '50%',
    height: '10px',
    width: '10px',
  },
  emptySpace: {
    height: '80vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  editContainer: {
    width: 500,
    padding: '8px 16px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flex: 1,
  },
  editDetails: {
    flex: 1,
    paddingBottom: '50px',
    overflow: 'scroll',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '0px',
    },
  },
  editBtns: {
    height: 70,
    background: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    position: 'absolute',
    width: '100%',
    left: 0,
    bottom: 0,
  },
  formControlLabel: {
    width: '100% !important',
    alignItems: 'center !important',
    marginLeft: '0px !important',
    marginBottom: '8px !important',
    '& .MuiFormControlLabel-label': {
      // marginLeft: '24px !important',
      '&.MuiTypography-root': {
        flex: 1,
        // marginBottom: '5px !important'
      },
    },
    '& .MuiRadio-root': {
      // marginTop: '2px !important'
      width: '20px !important',
    },
  },
  radioTypoContainer: {
    display: 'flex',
    // gap: '13px',
    // padding: '0px 5px !important'
  },
  sizeChart: {
    marginLeft: '20px !important',
    cursor: 'pointer',
  },
  sizeChartContainer: {},
  sizeChartImage: {},
  '@media (max-width: 600px)': {
    productDetailsSection: {
      padding: '14px 24px',
    },
    productImg: {
      maxHeight: 400,
      maxWidth: 300,
    },
    productCard: {
      marginTop: '8px',
      padding: '0',
    },
    moreImagesContainer: {
      justifyContent: 'start',
    },
    breadCrumbs: {
      paddingTop: 0,
      paddingBottom: '5px',
    },
    keyValueContainer: {
      gap: '0',
    },
  },
})

export default useStyles

export const DetailsContainer = styled.div`
  color: ${theme.SECONDARYTEXTCOLOR} !important;
  margin: 0 128px;
  button {
    color: ${theme.PRIMARYCOLOR};
    background-color: ${theme.SECONDARYTEXTCOLOR};
    border-color: ${theme.PRIMARYCOLOR};
    &:hover {
      background-color: ${theme.PRIMARYCOLOR};
      color: ${theme.SECONDARYTEXTCOLOR};
      border-color: ${theme.PRIMARYCOLOR};
    }
  }
  @media (max-width: 450px) {
    margin: 0;
  }
`
export const ProductImageSection = styled.div`
  width: 100%;
  border: 1px solid ${theme.BORDERCOLOR};
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
  background: ${theme.TERTIARYBACKGROUNDCOLOR};
  img {
    max-width: 500px;
    max-height: 290px;
    border-radius: 12px;
    margin: auto;
  }
  @media (max-width: 450px) {
    img {
      max-height: 270px;
    }
  }
`
export const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    max-width: 500px;
    height: 290px;
    border-radius: 12px;
    margin: auto;
  }
  @media (max-width: 450px) {
    img {
      max-height: 270px;
    }
  }
`
export const ProductContainer = styled.div`
  color: ${theme.PRIMARYCOLOR} !important;
`
export const MobileContainer = styled.div`
  display: grid;
  gap: 77px;
  justify-content: space-evenly;
  grid-template-columns: repeat(auto-fit, minmax(450px, 0fr));
  @media screen and (max-width: 450px) {
    margin: 0 25px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`
export const ProductDetailSection = styled.div`
  display: flex;
  gap: 40px;
  @media screen and (max-width: 450px) {
    flex-direction: column;
    gap: 0;
  }
`
export const ViewWrapper = styled.div`
  button {
    text-transform: none;
    padding: 0;
  }
`
export const Title = styled.div`
  display: flex;
  max-width: 179px;
  min-width: 179px;
  width: 100%;
  @media (max-width: 450px) {
    max-width: 100%;
  }
`
export const ShowMoreText = styled.div`
  color: ${theme.PRIMARYCOLOR};
  font-weight: 600;
  cursor: pointer;
`
export const QuantityContainer = styled.div`
  display: flex;
  justify-content: end;
  max-width: 210px;
  width: 100%;
  .quantity-button {
    max-height: 41px;
    max-width: 120px;
    width: 100%;
    border: 1px solid ${theme.SECONDARYBORDERCOLOR};
    border-radius: 6px;
    box-shadow: none;
    overflow: hidden;
    margin-top: 16px;
    :hover {
      background-color: ${theme.SECONDARYTEXTCOLOR};
      color: ${theme.PRIMARYCOLOR};
    }
  }

  .quantity {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-right: none !important;
    font-size: 20px;
    color: ${theme.PRIMARYCOLOR};
    width: 250px;
    padding: 10px;
  }

  button {
    font-size: 20px;
    border-right: none !important;
  }

  .decrease-quantity {
    font-size: 30px;
  }

  @media (max-width: 450px) {
    justify-content: start;
    max-width: 100%;
  }
`
export const PurchaseButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  .orderNow {
    color: ${theme.SECONDARYTEXTCOLOR};
    background-color: ${theme.PRIMARYCOLOR};
    border-radius: 8px;
    &:disabled {
      color: ${theme.DISABLECOLOR};
      background-color: transparent;
      border: 1px solid ${theme.GREYCOLORDARK};
    }
  }
  .cartButton {
    color: ${theme.PRIMARYTEXTCOLORDARK};
    background-color: ${theme.SECONDARYTEXTCOLOR};
    border-radius: 8px;
    box-shadow: none;
    border: 1px solid ${theme.SECONDARYBORDERCOLOR};
    border-radius: 8px;
    &:disabled {
      color: ${theme.DISABLECOLOR};
      background-color: transparent;
      border: 1px solid ${theme.GREYCOLORDARK};
    }
  }
  svg {
    path {
      stroke: ${theme.PRIMARYTEXTCOLORDARK};
    }
  }
  :hover {
    svg {
      path {
        stroke: ${theme.SECONDARYTEXTCOLOR};
      }
    }
  }
  @media (max-width: 450px) {
    flex-direction: column;
    width: 100%;
    button {
      margin-right: 0;
    }
  }
`
export const ErrorMessage = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.ERROR};
  padding: 2px 0;
  text-align: start;
`
export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;

  @media (max-width: 450px) {
    gap: 24px;
  }
`
export const BreadcrumbWrapper = styled.div`
  display: flex;
  padding: 20px 25px;
  background: ${theme.TERTIARYBACKGROUNDCOLOR};
  margin: 65px 0;
  .text-color {
    color: ${theme.HPSECONDARYCOLOR};
  }
  .title-color {
    color: ${theme.TEXTCOLOR};
  }
`
export const ProductDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const ProductInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const ProductName = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`
export const StoreName = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: ${theme.HPSECONDARYCOLOR};
  cursor: pointer;
`
export const PriceQuantityContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`
export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`
export const ProductPrice = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: ${theme.HPSECONDARYCOLOR};
  margin-top: 20px;
`
export const OriginalPrice = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: ${theme.PRIMARYGREYCOLOR};
  text-decoration: line-through;
  margin-top: 20px;
`
export const Discount = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 18px;
  color: ${theme.SECONDARYERRORCOLOR};
  margin-top: 20px;
`

export const ProductDeliveryDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  margin-top: 40px;
`
export const DeliveryTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: ${theme.HPSECONDARYCOLOR};
`
export const DeliveryDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`
export const DeliveryText = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${theme.HPSECONDARYCOLOR};
`
export const DetailWrapper = styled.div`
  display: flex;
  gap: 8px;
  @media (max-width: 500px) {
    flex-wrap: wrap;
  }
`

export const ReturnDetailWrapper = styled.div`
  display: flex;
`
export const ReturnText = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  span {
    color: ${theme.PRIMARYCOLOR};
    font-weight: 600;
  }
`
export const ReturnTime = styled(ReturnText)`
  color: ${theme.PRIMARYCOLOR};
  font-weight: 600;
`

export const AvailabeItem = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${theme.SECONDARYSUCCESSCOLOR};
`
export const UnavilableItem = styled(AvailabeItem)`
  color: ${theme.SECONDARYERRORCOLOR};
`
export const PincodeAvailable = styled(UnavilableItem)``

export const InfoTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  span {
    font-weight: 500;
    color: ${theme.HPSECONDARYCOLOR};
  }
`
export const InfoDetail = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.HPSECONDARYCOLOR};
`
export const TermsConditionWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding: 12px;
  background: ${theme.BACKGROUNDCOLOR};
  border-radius: 8px;
  max-width: 514px;
  width: 100%;
  margin-top: 24px;
`
export const LogoWrapper = styled.div``
export const Content = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.PRIMARYTEXTCOLOR};
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-left: 2px;
  }
  a {
    color: ${theme.PRIMARYCOLOR};
    text-decoration: none;
  }
`
export const ProductDetailHeading = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 18px;
  color: ${theme.PRIMARYTEXTCOLOR};
`
export const ProductInformationWrapper = styled.div`
  padding: 24px;
  overflow: auto;
  .accordian {
    border-bottom: 1px solid ${theme.SECONDARYBORDERCOLOR};
    padding: 0;
  }

  @media (max-width: 450px) {
    padding: 0 25px 14px 25px;
  }
  .accordian-detail {
    padding: 0 !important;
  }
`
export const ProductInformationTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.PRIMARYGREYCOLOR};
  text-transform: capitalize;
`
export const ProductInformationDescripton = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`
export const VariantsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 18px;
`
export const VariantsSection = styled.div`
  display: flex;
  flex-direction: column;
`
export const VariantTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`
export const VariantSizeContainer = styled.div`
  display: flex;
  gap: 18px;
`
export const VariantSizeWrapper = styled.div`
  border: 1px solid ${theme.BORDERCOLORDARK};
  border-radius: 4px;
  background: ${theme.SECONDARYBACKGROUNDCOLOR};
  padding: 8px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.PRIMARYTEXTCOLOR};
`
export const VariantColorWrapper = styled.div`
  border: 1px solid ${theme.BORDERCOLORDARK};
  border-radius: 4px;
  padding: 8px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.PRIMARYTEXTCOLOR};
  width: 80px;
`
export const ProductImagesWrapper = styled.div`
  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    gap: 10px !important;
  }
  .slick-prev:before {
    display: none;
  }
  .slick-next:before {
    display: none;
  }
  .slick-prev {
    color: ${theme.SECONDARYTEXTCOLOR};
    &:hover {
      background: ${theme.PRIMARYCOLOR};
    }
  }
  .slick-next {
    color: ${theme.SECONDARYTEXTCOLOR};
    &:hover {
      background: ${theme.PRIMARYCOLOR};
    }
  }
  .slick-list {
    margin: 0 15px;
  }
  @media (max-width: 1500px) {
    .slick-list {
      margin: 0 15px;
    }
  }
`
export const ImageContainer = styled.div`
  border: 1px solid ${theme.BORDERCOLORLIGHT};
  border-radius: 8px;
  padding: 6px;
  width: 90px !important;
`
export const IconButton = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background: ${theme.PRIMARYCOLOR};
`
export const WishlistSection = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const WishlistWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  cursor: pointer;
  svg {
    stroke-width: 2px;
  }
  path {
    stroke: ${theme.SECONDARYERRORCOLOR};
  }
  .filled-icon {
    path {
      fill: ${theme.SECONDARYERRORCOLOR};
      stroke: ${theme.SECONDARYERRORCOLOR};
    }
  }
  @media (max-width: 767px) {
    width: 30px;
    height: 30px;
  }
`
