import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 180px 100px 30px;
  gap: 74px;
  @media (max-width: 450px) {
    margin: 150px 24px 30px;
    gap: 40px;
  }
`
export const MainHeading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 42px;
  line-height: 51.2px;
  color: ${theme.HPPRIMARYTEXTCOLORDARK};
  @media (max-width: 450px) {
    font-size: 24px;
    line-height: 29.26px;
  }
`
export const CategoryContainer = styled.div`
  display: flex;
  gap: 44px;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`
export const CategoryListsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 294px;
  max-width: 294px;
  width: 100%;
`

export const Heading = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.HPPRIMARYTEXTCOLORDARK};
  text-transform: uppercase;
`
export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const ProductListingWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 16px;
  .empty-state {
    min-height: 62vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  @media (max-width: 450px) {
    justify-content: center;
  }
`
export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`
export const OutOfStockWrapper = styled.div`
  margin: 0 0 0 15px;
`
export const ProductImageWrapper = styled.div`
  position: relative;
  cursor: pointer;
  img {
    height: 228px;
    width: 290px;
    border-radius: 8px;
    overflow: hidden;
    object-fit: contain;
  }
`
export const ProductName = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: ${theme.HPPRIMARYTEXTCOLORDARK};
  max-width: 290px;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const WishlistWrapper = styled.div`
  position: absolute;
  right: 0;
  top: -16px;
  svg {
    cursor: pointer;
  }
`
export const PriceWrapper = styled.div`
  display: flex;
  gap: 5px;
  border-top: 1px solid ${theme.HPPRIMARYBORDERCOLOR};
  padding: 12px 0 0;
`
export const OriginalPrice = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.HPTEXTCOLORTERTIONARY};
  text-decoration: line-through;
`
export const ProductPrice = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${theme.HPPRIMARYTEXTCOLORDARK};
`
export const DiscountPercentage = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  color: ${theme.HPSECONDARYCOLOR};
  border: 1px solid ${theme.HPPRIMARYBORDERCOLOR};
  border-radius: 5px;
  padding: 0 4px;
  height: 22px;
  text-align: center;
`
export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  button {
    text-transform: none;
    border-radius: 8px;
    padding: 3px;
    font-size: 12px;
    height: 36px;
  }
  .cart-button {
    width: 50px;
    padding: 0;
    &:hover {
      background-color: transparent;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
`
export const NoDataWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
