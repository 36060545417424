import React, { useContext, useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useHistory, useParams } from 'react-router-dom'
import Checkbox from '@mui/material/Checkbox'
import Pagination from '@mui/material/Pagination'
import FormControlLabel from '@mui/material/FormControlLabel'
import axios from 'axios'
import useGet from 'hooks/useGet'
import useCancellablePromise from 'api/cancelRequest'
import { postLoginCall, deleteWithAuthentication } from 'api/axios'
import { getUserId, getOrCreateDeviceId, formatIndianRupees } from 'helper'
import { AddCookie, getValueFromCookie } from 'utils/cookies'
import { isLoggedIn } from 'utils/validateToken'
import { CalculateDiscount } from 'utils/helper'
import { search_types } from 'constants/searchTypes'
import { ToastContext } from 'context/toastContext'
import { SearchContext } from 'context/searchContext'
import { CartContext } from 'context/cartContext'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import CustomButton from 'components/customButton'
import CustomizationRenderer from 'components/application/product-list/product-details/CustomizationRenderer'
import ModalComponent from 'components/common/Modal'
import Spinner from 'components/Loader'
import OutOfStock from 'components/outOfStock'
import NoDataFound from 'views/EmptyData'
import LoginModal from 'views/LoginModal'
import PlaceOrderModal from 'views/OrderModal'
import HpWishlistIcon from 'assets/svg/HpWishlistIcon'
import ButtonIcon from 'assets/images/HpImages/Button.png'
import WishlistFilledIcon from 'assets/svg/WishlistFilledIcon'
import {
  Container,
  MainHeading,
  CategoryContainer,
  CategoryListsContainer,
  Heading,
  ListContainer,
  ProductListingWrapper,
  CardWrapper,
  OutOfStockWrapper,
  ProductImageWrapper,
  ProductName,
  WishlistWrapper,
  PriceWrapper,
  OriginalPrice,
  ProductPrice,
  DiscountPercentage,
  ButtonWrapper,
  NoDataWrapper,
  PaginationWrapper,
} from 'styles/hpLayout/categories'

const Categories = () => {
  const { category } = useParams()
  let categoryParam = category
  const categories = [
    { domain: 'All', name: 'All' },
    // { domain: 'ONDC:RET10', name: 'Masala and Seasoning' },
    { domain: 'ONDC:RET10', name: 'Oil & Ghee' },
    { domain: 'ONDC:RET10', name: 'Pickles and Chutney' },
    { domain: 'ONDC:RET10', name: 'Tea and Coffee' },
    { domain: 'ONDC:RET10', name: 'Salt, Sugar and Jaggery' },
    { domain: 'ONDC:RET10', name: 'Tinned and Processed Food' },
    { domain: 'ONDC:RET10', name: 'Snacks and Namkeen' },
    { domain: 'ONDC:RET18', name: 'Speciality Care' },
    { domain: '', name: 'Gift Box' },
    // { domain: 'ONDC:RET10', name: 'Snacks' },
    // { domain: 'ONDC:RET10', name: 'Dry Fruits' },
    // { domain: 'ONDC:RET10', name: 'Nuts' },
    // { domain: 'ONDC:RET10', name: 'Ready to cook and Eat' },
    // { domain: 'ONDC:RET10', name: 'Food Grains' },
    // { domain: 'ONDC:RET12', name: 'Beauty and Hygiene' },
    // { domain: 'ONDC:RET18', name: 'Ayurvedic' },
  ]

  useEffect(() => {
    if (categoryParam) {
      const findDomain = (categoryParam) => {
        return categories.filter((item) => item.name === categoryParam).map((el) => el.domain)
      }
      const domain = findDomain(category)

      setSelectedCategories([{ domain: domain[0], name: categoryParam }])
    }
  }, [category])

  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 10,
  })
  const [products, setProducts] = useState([])
  const [totalProductCount, setTotalProductCount] = useState(0)
  const [orderModal, setOrderModal] = useState(false)
  const [buyNow, setBuyNow] = useState(false)
  const [loginModal, setLoginModal] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [deviceId, setDeviceId] = useState(null)
  const [buttonDisable, setButtonDisable] = useState({})
  const [customization_state, setCustomizationState] = useState({})
  const [loading, setLoading] = useState({})
  const [selectedCategories, setSelectedCategories] = useState([{ domain: 'All', name: 'All' }])
  const dispatch = useContext(ToastContext)
  const { setSearchData, setLocationData } = useContext(SearchContext)
  const { fetchCartItems, cartItems } = useContext(CartContext)
  const userId = getUserId()
  const { cancellablePromise } = useCancellablePromise()
  const history = useHistory()

  const useQuery = () => {
    // const { search } = locationData
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  const [search, setSearch] = useState({
    type: search_types.PRODUCT,
    value: '',
  })
  const [searchedLocation, setSearchedLocation] = useState({
    name: '',
    lat: '',
    lng: '',
  })

  const query = useQuery()

  function getLastEnteredValues() {
    const searchProductName = query.get('s')
    let search_context = getValueFromCookie('search_context')
    if (search_context) {
      search_context = Object.assign({}, JSON.parse(search_context))
      setSearch(() => ({
        type: search_context.search.type,
        value: query.size > 0 && searchProductName ? searchProductName : '',
      }))
      setSearchedLocation(search_context.location)
      setSearchData(() => ({
        type: search_context.search.type,
        value: query.size > 0 && searchProductName ? searchProductName : '',
      }))
      setLocationData(() => search_context.location)
    }
  }

  useEffect(() => {
    getLastEnteredValues()
  }, [])

  let selectedCustomizationIds = []

  const getCustomization_ = (groupId) => {
    let group = customization_state[groupId]
    if (!group) return

    group.selected.map((s) => selectedCustomizationIds.push(s.id))
    group?.childs?.map((child) => {
      getCustomization_(child)
    })
  }

  const getCustomizations = (product) => {
    if (!product?.customisation_items?.length) return null
    const customizations = []

    const firstGroupId = customization_state['firstGroup']?.id

    if (!firstGroupId) return
    getCustomization_(firstGroupId)

    for (const cId of selectedCustomizationIds) {
      let c = product?.customisation_items.find((item) => item.local_id === cId)
      if (c) {
        c = {
          ...c,
          quantity: {
            count: 1,
          },
        }
        customizations.push(c)
      }
    }

    return customizations
  }

  const getAllProducts = async (selectedDomains = [], selectedCategoryIds = []) => {
    setLoading(true) // Set global loading to true when fetching products
    const getPinCode = localStorage.getItem('pinCode')
    const currentPinCode = localStorage.getItem('currentpinCode')
    const deviceId = await getOrCreateDeviceId()
    const userId = getUserId() || 'guestUser'

    let baseUrl = `/clientApis/v2/search/${userId ? userId : deviceId}`
    const params = {
      deviceId,
      pincode: getPinCode || currentPinCode,
      page: paginationModel.page,
      limit: paginationModel.pageSize,
    }

    if (!selectedDomains.includes('All') && !selectedCategoryIds.includes('All')) {
      if (selectedDomains.length > 0) {
        params.domain = selectedDomains.join(',')
      }

      if (selectedCategoryIds.length > 0) {
        params.categoryId = selectedCategoryIds.join('|')
      }
    }

    try {
      const { data } = await cancellablePromise(axios.get(baseUrl, { params }))
      setProducts(data?.response?.data)
      setTotalProductCount(data?.response?.count)
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'Product details not found',
        },
      })
    } finally {
      setLoading(false) // Set global loading to false after products are fetched
    }
  }

  const handleCategoryChange = (event) => {
    categoryParam = null
    const { value, checked } = event.target

    const [selectedDomain, selectedName] = value.split('|')

    if (checked) {
      if (selectedDomain === 'All') {
        setSelectedCategories([{ domain: 'All', name: 'All' }]) // Deselect other categories and select "All"
      } else {
        setSelectedCategories((prev) => prev.filter((cat) => cat.domain !== 'All')) // Deselect "All" if other categories selected
        setSelectedCategories((prev) => [...prev, { domain: selectedDomain, name: selectedName }])
      }
    } else {
      setSelectedCategories((prev) =>
        prev.filter((category) => category.domain !== selectedDomain || category.name !== selectedName),
      )
    }
  }

  useEffect(() => {
    const findDomain = (categoryParam) => {
      return categories.filter((item) => item.name === categoryParam).map((el) => el.domain)
    }
    const selectedDomains = findDomain(category)
    if (categoryParam !== undefined) {
      const selectedCategoryIds = selectedCategories.map((cat) => cat.name)
      getAllProducts(selectedDomains, selectedCategoryIds)
    } else {
      const selectedDomains = selectedCategories.map((cat) => cat.domain)
      const selectedCategoryIds = selectedCategories.map((cat) => cat.name)
      getAllProducts(selectedDomains, selectedCategoryIds)
    }
  }, [selectedCategories, paginationModel.page])

  // Add to cart //
  const handleAddToCart = async (product) => {
    const userId = getUserId()

    if (buttonDisable[product.id]) return
    ReactGA.event({
      category: 'Top Selling',
      action: 'Click',
      label: 'Add to Cart',
    })
    // setLoading((prevState) => ({ ...prevState, [product.id]: true }))

    let searchDataUpdate = Object.assign({}, JSON.parse(JSON.stringify(search)))
    const search_context = {
      search: searchDataUpdate,
      location: searchedLocation,
    }
    AddCookie('search_context', JSON.stringify(search_context))
    const deviceId = await getOrCreateDeviceId()

    const url = `/clientApis/v2/cart/${userId}/${deviceId}`

    // Pass getCustomizations(product) directly to the payload
    const payload = {
      customisations: getCustomizations(product),
      hasCustomisations: !!getCustomizations(product),
      customisationState: customization_state,
      local_id: product?.local_id,
      id: product?.id,
      provider: {
        id: product?.provider_details?.id,
      },
      quantity: {
        count: 1,
      },
    }

    try {
      const data = await postLoginCall(url, payload)
      if (data) {
        fetchCartItems()
        localStorage.setItem('cartItems', JSON.stringify(data))
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.success,
            message: 'Item added to cart successfully.',
          },
        })
      }

      // setLoading((prevState) => ({ ...prevState, [product.id]: false }))
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message:
            'Unfortunately, We’re experiencing some technical issues while adding items to your cart. Please bear with us & get back to us sometime.',
        },
      })
    }
  }

  // Buy Now //
  const handleBuyNow = (product) => {
    ReactGA.event({
      category: 'Top Selling',
      action: 'Click',
      label: 'Buy Now',
    })

    setSelectedProduct(product)
    if (!isLoggedIn()) {
      setBuyNow(true)
      setLoginModal(true)
      return
    } else {
      setOrderModal(true)
    }
  }

  const execute = async () => {
    const deviceId = await getOrCreateDeviceId()
    setDeviceId(deviceId)
  }

  useEffect(() => {
    execute()
  }, [])

  useEffect(() => {
    if (categoryParam) {
      const findDomain = (categoryParam) => {
        return categories.filter((item) => item.name === categoryParam).map((el) => el.domain)
      }
      const domain = findDomain(category)
      setSelectedCategories([{ domain: domain[0], name: categoryParam }])
    }
  }, [category])

  //Add to wishlist//
  const handleWishlist = async (product) => {
    const userId = getUserId()
    const { item_details } = product
    if (!isLoggedIn()) {
      setLoginModal(true)
      return
    }

    // fetchWishlist()
    const deviceId = await getOrCreateDeviceId()

    const url = `/clientApis/v2/wishlist/${userId}/${deviceId}`
    // const subtotal = product?.item_details?.price?.value

    // Pass getCustomizations(product) directly to the payload
    const payload = {
      local_id: product?.local_id,
      id: product?.id,
      provider: {
        id: product?.provider_details?.id,
      },
      quantity: {
        count: 1,
      },
    }

    setProducts((prevItems) =>
      prevItems.map((item) => (item.item_details.id === item_details?.id ? { ...item, wishlistAdded: true } : item)),
    )

    try {
      const res = await postLoginCall(url, payload)
      if (res.status !== 'error') {
        localStorage.setItem('wishlistItems', JSON.stringify(res))
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.success,
            message: 'Item added to wishlist successfully.',
          },
        })
      } else {
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: 'It looks like the item already exists in the Wishlist. Please check',
          },
        })
      }
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'Sorry, you cannot add this item to your Wishlist due to technical glitch. Please try again.',
        },
      })
    }
  }

  //Remove from wishlist//
  const handleRemoveFromTopSellingWishlist = async (item_details, e) => {
    const userId = getUserId()
    e.stopPropagation()

    if (!isLoggedIn()) {
      setLoginModal(true)
      return
    }

    try {
      const deviceId = await getOrCreateDeviceId()
      const url = `/clientApis/v2/item/wishlist/${userId}/${deviceId}/${item_details?.id}`
      await deleteWithAuthentication(url)
      setProducts((prevItems) =>
        prevItems.map((item) => (item.item_details.id === item_details?.id ? { ...item, wishlistAdded: false } : item)),
      )
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.success,
          message: 'Item removed from your Wishlist',
        },
      })
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'Sorry, we hit a snag while fetching the top selling products. Please try again.',
        },
      })
    }
  }

  //Fetch wishlist//
  const { refetch: fetchWishlist } = useGet('wishlist', deviceId && `/clientApis/v2/wishlist/${userId}/${deviceId}`)

  useEffect(() => {
    if (deviceId) fetchWishlist()
  }, [deviceId])

  const handlePaginationChange = (event, page) => {
    event
    setPaginationModel({ ...paginationModel, page })
    window.scrollTo({ top: 0, behavior: 'smooth' }) // Scroll to top when pagination changes
  }

  useEffect(() => {
    if (selectedCategories.length === 0) {
      setSelectedCategories([{ domain: 'All', name: 'All' }])
    }
  }, [selectedCategories])

  useEffect(() => {
    if (products && products.length > 0) {
      const buttonStates = products.reduce((acc, product) => {
        const inCart = cartItems.some((item) => item.item.id === product.id)
        acc[product.id] = inCart
        return acc
      }, {})

      setButtonDisable(buttonStates)
    }
  }, [cartItems, products])

  return (
    <Container>
      <MainHeading>Explore All Categories</MainHeading>
      <CategoryContainer>
        <CategoryListsContainer>
          <Heading>Category</Heading>
          <ListContainer>
            {categories?.map((category) => (
              <FormControlLabel
                key={`${category.domain}|${category.name}`}
                control={
                  <Checkbox
                    className="checkbox-group"
                    checked={selectedCategories.some(
                      (cat) => cat.domain === category.domain && cat.name === category.name,
                    )}
                    onChange={handleCategoryChange}
                    value={`${category.domain}|${category.name}`}
                  />
                }
                label={category.name}
              />
            ))}
          </ListContainer>
        </CategoryListsContainer>
        <ProductListingWrapper>
          {loading ? (
            <Spinner />
          ) : products?.length > 0 ? (
            products?.map((item, index) => {
              const { item_details } = item
              const maxProductValue = parseFloat(item?.item_details?.price?.maximum_value).toFixed(2)
              const productValue = parseFloat(item?.item_details?.price?.value).toFixed(2)
              const discount = CalculateDiscount(
                item?.item_details?.price?.maximum_value,
                item?.item_details?.price?.value,
              )
              return (
                <CardWrapper key={index}>
                  <OutOfStockWrapper>
                    {item_details?.quantity?.available?.count === '0' ? <OutOfStock /> : ''}
                  </OutOfStockWrapper>
                  <ProductImageWrapper>
                    <WishlistWrapper>
                      {item.wishlistAdded ? (
                        <WishlistFilledIcon
                          onClick={async (e) => {
                            handleRemoveFromTopSellingWishlist(item_details, e)
                          }}
                        />
                      ) : (
                        <HpWishlistIcon
                          onClick={() => {
                            handleWishlist(item)
                          }}
                        />
                      )}
                    </WishlistWrapper>
                    <img
                      src={item_details?.descriptor?.symbol}
                      alt="product-image"
                      onClick={() => {
                        history.push(`/products?productId=${item?.id}`)
                      }}
                      onError={(e) => {
                        e.target.src =
                          'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                      }}
                    />
                  </ProductImageWrapper>
                  <ProductName>{item_details?.descriptor?.name}</ProductName>
                  <PriceWrapper>
                    {maxProductValue > productValue && (
                      <OriginalPrice> ₹{formatIndianRupees(parseFloat(maxProductValue))}</OriginalPrice>
                    )}
                    <ProductPrice>₹{formatIndianRupees(parseFloat(productValue))}</ProductPrice>
                    {discount > 0 && <DiscountPercentage>{Math.round(discount)}% OFF</DiscountPercentage>}
                  </PriceWrapper>
                  <ButtonWrapper>
                    <CustomButton
                      variant="text"
                      className="cart-button"
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        handleAddToCart(item)
                        setButtonDisable((prev) => ({ ...prev, [item?.id]: true }))
                      }}
                      disabled={
                        item_details?.quantity?.available?.count === '0' || buttonDisable[item?.id] || loading[item?.id]
                      }
                      icon={<img src={ButtonIcon} height={36} width={36} alt="Button Icon" />}
                    />

                    <CustomButton
                      label={'Buy Now'}
                      variant="contained1"
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        handleBuyNow(item)
                      }}
                      disabled={item_details?.quantity?.available?.count === '0'}
                    />
                  </ButtonWrapper>
                </CardWrapper>
              )
            })
          ) : (
            <NoDataWrapper className="empty-state">
              <NoDataFound />
            </NoDataWrapper>
          )}
        </ProductListingWrapper>
      </CategoryContainer>
      <PaginationWrapper>
        {products && products?.length > 0 && (
          <Pagination
            count={Math.ceil(totalProductCount / paginationModel.pageSize)}
            shape="rounded"
            color="primary"
            page={paginationModel.page}
            onChange={handlePaginationChange}
          />
        )}
      </PaginationWrapper>

      <CustomizationRenderer customization_state={customization_state} setCustomizationState={setCustomizationState} />

      {loginModal && (
        <ModalComponent open={loginModal} onClose={() => setLoginModal(false)}>
          <LoginModal onClose={() => setLoginModal(false)} buyNow={buyNow} setOrderModal={setOrderModal} />
        </ModalComponent>
      )}
      {orderModal && (
        <ModalComponent
          open={orderModal}
          onClose={() => setOrderModal(false)}
          title="Get Ready To Shop !"
          titleBg={true}
        >
          <PlaceOrderModal onClose={() => setOrderModal(false)} product={selectedProduct} />
        </ModalComponent>
      )}
    </Container>
  )
}

export default Categories
