import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'
import paperImage from 'assets/images/HpImages/featureBackground-image.png'
import mobileBackgroundImage from 'assets/images/HpImages/mobileFeature-backgroundImage.png'

export const MainContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 0;
  margin-top: 80px;
  @media (max-width: 1300px) {
    margin-top: 10px;
  }
`
export const FeatureBackgroungWrapper = styled.div`
  /* position: relative; */
  max-width: 100%;
  img {
    /* position: relative; */
    width: 100vw;
    max-width: 100%;
    /* height: auto; */
  }
  @media (max-width: 767px) {
    display: none;
  }
`
export const PaperContainer = styled.div`
  position: absolute;
  top: 80px;
  width: 80%;
  height: 100vh;
  background: url(${paperImage}) no-repeat center center;
  background-size: contain;
  z-index: 9999999;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 65px;
  @media (max-width: 1500px) {
    width: 100%;
  }
  @media (max-width: 1300px) {
    height: 980px;
    top: 0px;
  }
  /* @media (max-width: 1300px) {
    width: 100%;
    height: auto;
    top: 177px;
  }
  @media (max-width: 767px) {
    display: block;
    width: 100%;
    height: 100%;
    top: 0px;
    background-size: contain;
  } */
  @media (max-width: 767px) {
    position: static;
  }
  @media (max-width: 500px) {
    display: block;
    background: url(${mobileBackgroundImage}) no-repeat;
    width: 100%;
    height: 511px;
    top: 20px;
    background-size: cover;
  }
`
export const MainHeading = styled.div`
  font-weight: 700;
  font-size: 42px;
  line-height: 51.2px;
  color: ${theme.HPPRIMARYTEXTCOLORDARK};
  @media (max-width: 767px) {
    display: none;
  }
`
export const WebContentGrid = styled.div`
  width: 70%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  /* padding: 50px; */
  z-index: 1;
  @media (max-width: 1300px) {
    width: 82%;
    grid-gap: 16px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`
export const ContentBlock = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  @media (max-width: 767px) {
    justify-content: center;
    align-items: center;
    max-width: 500px;
    text-align: center;
  }
  @media (max-width: 500px) {
    justify-content: center;
    align-items: center;
    max-width: 240px;
    text-align: center;
  }
`

export const Icon = styled.div``
export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const Title = styled.h3`
  font-weight: 700;
  font-size: 17.15px;
  line-height: 26px;
  letter-spacing: 1.01px;
  color: ${theme.HPSECONDARYCOLOR};
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 28px;
  }
`
export const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.HPTEXTCOLORLIGHT};
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 20.58px;
  }
`
export const MobileContentGrid = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
    width: 100%;
    .slick-track {
      display: flex;
    }
    .slick-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 119px;
    }
    .slick-dots li.slick-active button:before {
      opacity: 0.75;
      color: ${theme.HPSECONDARYCOLOR};
      font-size: 10px;
    }
    .slick-dots li button:before {
      font-size: 10px;
    }
  }
  @media (max-width: 500px) {
    .slick-slide {
      margin-top: 63px;
    }
  }
`
