import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const Container = styled.div`
  display: flex;
  /* margin: 295px 100px 80px 100px; */
  margin: 295px 100px 0 100px;
  /* padding: 60px 100px; */
  gap: 100px;

  @media (max-width: 1300px) {
    margin: 430px 100px 0 100px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    margin: 130px 24px 50px 24px;
    gap: 24px;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    margin: 30px 24px 0 24px;
    gap: 24px;
  }
  @media (max-width: 400px) {
    flex-direction: column;
    margin: 68px 24px 83px 24px;
    gap: 24px;
  }
`
export const ProductImageSection = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: 767px) {
    justify-content: center;
  }
`
export const ProductMainImage = styled.div`
  img {
    width: 335px;
    height: 410px;
    /* width: 100%;
    height: auto; */
    border-radius: 24px;
    overflow: hidden;
    object-fit: contain;
  }
  @media (max-width: 767px) {
    img {
      width: 312px;
      height: 197px;
      border-radius: 24px;
    }
  }
`
export const ProductIngredientsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 500px) {
    display: none;
  }
`
export const IngredientsImage = styled.div`
  img {
    width: 335px;
    height: 197px;
    border-radius: 24px;
    overflow: hidden;
  }
`

export const DescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 64px;
  @media (max-width: 767px) {
    gap: 24px;
  }
`
export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
export const MainHeading = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 39.01px;
  color: ${theme.HPSECONDARYCOLOR};
  @media (max-width: 767px) {
    display: none;
  }
`
export const MobileMainHeading = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 29.26px;
    color: ${theme.HPSECONDARYCOLOR};
  }
`
export const ProccessDescription = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: ${theme.HPTEXTCOLORLIGHT};
  @media (max-width: 767px) {
    font-size: 16px;
  }
`
export const ButtonWrapper = styled.div`
  button {
    display: flex;
    gap: 6px;
    max-width: 235px;
    width: 100%;
  }
`
