import React, { useContext, useEffect, useState } from 'react'
import HpLogo from 'assets/images/HpImages/hp-logo.png'
import SearchIcon from 'assets/svg/HPSvgs/SearchIcon'
import WishlistIcon from 'assets/svg/HPSvgs/WishlistIcon'
import CartIcon from 'assets/svg/HPSvgs/CartIcon'
import ProfileIcon from 'assets/svg/HPSvgs/ProfileIcon'
import MobileSearchIcon from 'assets/svg/HPSvgs/MobileSearchIcon'
import MobileCartIcon from 'assets/svg/HPSvgs/MobileCartIcon'
import MobileWishlistIcon from 'assets/svg/HPSvgs/MobileWishlistIcon'
import {
  IconContainer,
  IconWrapper,
  LogoWrapper,
  MenuItem,
  NavbarContainer,
  NavbarContentWrapper,
  NavbarUpperSection,
  NavbarWrapper,
  Text,
  MobileLogoWrapper,
  MobileWrapper,
  DrawerContainer,
  MenuItemSection,
  ListWrapper,
  ListItems,
  ListOptions,
  PincodeWrapper,
  PincodeText,
  UserWrapper,
  ImageWrapper,
  UserName,
  MenuItemWrapper,
  IconsWrapper,
  SelectPincodeText,
  TextWrapper,
} from 'styles/hpLayout/navbar'
import { isLoggedIn } from 'utils/validateToken'
import CloseIcon from '@mui/icons-material/Close'
import ModalComponent from 'components/common/Modal'
import LoginModal from 'views/LoginModal'
import { useHistory, useLocation, Link } from 'react-router-dom'
import MenuIcon from 'assets/svg/MenuIcon'
import { Drawer } from '@mui/material'
import { Badge, InputAdornment, TextField } from '@mui/material'
import { CartContext } from 'context/cartContext'
import { getOrCreateDeviceId } from 'helper'
import { LocationContext } from 'context/locationContext'
import { Axios } from 'axios'
import { getCall } from 'api/axios'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import { ToastContext } from 'context/toastContext'
import { ReactComponent as LocationIcon } from 'assets/images/location.svg'
import Pincode from '../pincode/pincode'
import { search_types } from 'constants/searchTypes'
import { getSelectCall } from 'api/axios'
import useCancellablePromise from 'api/cancelRequest'
import { SearchContext } from 'context/searchContext'
import { AddCookie } from 'utils/cookies'
import UserImage from 'assets/images/user-image.jpeg'
import Cookies from 'js-cookie'
import LoginIcon from 'assets/svg/LoginIcon'
import LogOutIcon from 'assets/svg/LogOutIcon'
import ConfirmationModal from 'views/ConfirmationModal'
const Navbar = () => {
  const history = useHistory()
  const dispatch = useContext(ToastContext)
  const { cartItems, fetchCartItems } = useContext(CartContext)
  const { setLocation, latitude, longitude } = useContext(LocationContext)
  const getPinCode = localStorage.getItem('pinCode')
  const currentPinCode = localStorage.getItem('currentpinCode')
  const { cancellablePromise } = useCancellablePromise()
  const CheckToken = Cookies.get('token')

  const [loginModal, setLoginModal] = useState(false)
  const [pincodeModal, setPincodeModal] = useState(false)
  const [open, setOpen] = useState(false)
  const [currentPincode, setCurrentPinCode] = useState('')
  const [searchBar, setSearchBar] = useState(false)
  const [userData, setUserData] = useState({})
  const [deviceId, setDeviceId] = useState('')
  const [logout, setLogout] = useState(false)

  const [search, setSearch] = useState({
    type: search_types.PRODUCT,

    value: '',
  })

  const locationData = useLocation()
  const isMobileView = window.innerWidth < 768
  const useQuery = () => {
    const { search } = locationData

    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useQuery()

  const { setSearchData } = useContext(SearchContext)
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen)
  }

  const getUserProfile = async () => {
    const res = await cancellablePromise(getSelectCall(`/clientApis/getUserProfile`))
    const userData = res.data
    setUserData(userData) //setUserData
  }

  useEffect(() => {
    if (isLoggedIn()) {
      getUserProfile()
    }
  }, [isLoggedIn(), open])

  useEffect(() => {
    if (!getPinCode && !currentPinCode) {
      setPincodeModal(true)
    }
  }, [getPinCode, currentPinCode])

  const handleUserProfile = () => {
    setSearchBar(false)
    if (!isLoggedIn()) {
      setLoginModal(true)
      return
    } else {
      history.push('/profile/user-info')
    }
  }

  const handleWishlist = () => {
    setSearchBar(false)
    if (!isLoggedIn()) {
      setLoginModal(true)
      return
    } else {
      history.push('/profile/wishlist')
    }
  }

  const handleOrderHistory = () => {
    setSearchBar(false)
    if (!isLoggedIn()) {
      setLoginModal(true)
      return
    } else {
      history.push('/profile/orders')
      setOpen(false)
    }
  }

  const handleComplaints = () => {
    setSearchBar(false)
    if (!isLoggedIn()) {
      setLoginModal(true)
      return
    } else {
      history.push('/profile/complaint')
      setOpen(false)
    }
  }

  const handleCart = () => {
    setSearchBar(false)
    history.push('/cart')
  }

  const handleSearch = () => {
    setSearchBar(!searchBar)
  }

  const handleLogOut = () => {
    setLogout(true)
    setOpen(false)
  }

  const handleLogin = () => {
    if (!isLoggedIn()) {
      setLoginModal(true)
      setOpen(false)
      return
    }
  }

  const NavbarItems = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Categories',
      link: '/categories',
    },
    {
      text: 'Blogs',
      link: '/blogs',
    },

    {
      text: 'Contact Us',
      link: '/contact-us',
    },
    {
      text: 'About Us',
      link: '/about-us',
    },
  ]
  const MobileNavbarItems = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Profile',
      onClick: handleUserProfile,
    },
    {
      text: 'Orders',
      onClick: handleOrderHistory,
    },
    {
      text: 'Complaints',
      onClick: handleComplaints,
    },
    {
      text: 'ContactUs',
      link: '/contact-us',
    },
    {
      text: 'Categories',
      link: '/categories',
    },
    {
      text: 'Blogs',
      link: '/blogs',
    },
  ]

  const Icons = [
    {
      icon: searchBar ? <CloseIcon /> : <SearchIcon />,
      handleClick: handleSearch,
    },
    {
      icon: <WishlistIcon />,
      handleClick: handleWishlist,
    },
    {
      icon: (
        <Badge color="error" badgeContent={cartItems?.length}>
          <CartIcon />
        </Badge>
      ),
      handleClick: handleCart,
    },
    {
      icon: <ProfileIcon />,
      handleClick: handleUserProfile,
    },
  ]
  const MobileIcons = [
    {
      icon: searchBar ? <CloseIcon /> : <MobileSearchIcon />,
      handleClick: handleSearch,
    },
    {
      icon: <MobileWishlistIcon />,
      handleClick: handleWishlist,
    },
    {
      icon: (
        <Badge color="error" badgeContent={cartItems?.length}>
          <MobileCartIcon />
        </Badge>
      ),
      handleClick: handleCart,
    },
  ]
  const isHomepage = location.pathname === '/'

  const trimCoordinates = (lat, long) => {
    const trimToSixDecimals = (num) => {
      const parsedNum = parseFloat(num)
      return isNaN(parsedNum) ? null : parsedNum.toFixed(6)
    }

    return {
      latitude: trimToSixDecimals(lat),
      longitude: trimToSixDecimals(long),
    }
  }

  useEffect(() => {
    const getLocation = () => {
      try {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            setLocation(position.coords.latitude.toFixed(6), position.coords.longitude.toFixed(6))
          })
        }
      } catch (error) {
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: 'Cant able to fetch Current Location',
          },
        })
      }
    }

    const getPincodeCoordinates = async () => {
      const { data: pincodeCoordinatesData, status } = await Axios.get(
        `https://nominatim.openstreetmap.org/search?q=${getPinCode}&format=json&limit=1&countrycodes=IN`,
      )

      if (status === 200) {
        const lat = pincodeCoordinatesData[0]?.lat
        const long = pincodeCoordinatesData[0]?.lon

        const trimmedCoordinates = trimCoordinates(lat, long)
        setLocation(trimmedCoordinates?.latitude, trimmedCoordinates?.longitude)
      }
    }

    if (!getPinCode) {
      getLocation()
    } else {
      getPincodeCoordinates()
    }
  }, [])
  const getCurrentPinCode = async () => {
    try {
      const data = await getCall(`/clientApis/v2/map/getPinCode?lat=${latitude}&lon=${longitude}`)
      setCurrentPinCode(data?.data?.postcode)
      localStorage.setItem('currentpinCode', data?.data?.postcode)
      return data
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'Cant Fetch Pincode for your Current Location',
        },
      })
    }
  }

  useEffect(() => {
    if (latitude && longitude) {
      getCurrentPinCode()
    }
  }, [latitude, longitude])

  const drawerList = (
    <DrawerContainer>
      <UserWrapper>
        <ImageWrapper>
          <img
            // src={user && user.photoURL ? user.photoURL : UserImage}
            src={userData && userData?.userImage ? userData?.userImage : UserImage}
            onError={(e) => {
              e.target.src =
                'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
            }}
          />
        </ImageWrapper>
        <UserName>{userData?.userName ? userData?.userName : ''}</UserName>
      </UserWrapper>
      <MenuItemWrapper>
        <MenuItemSection>
          <ListWrapper onClick={() => setOpen(false)}>
            {MobileNavbarItems?.map((item, index) => (
              <Link key={index} to={item.link} onClick={item.onClick}>
                <ListItems>
                  <ListOptions>{item.text}</ListOptions>
                </ListItems>
              </Link>
            ))}
          </ListWrapper>
        </MenuItemSection>
        {CheckToken && (
          <ListWrapper>
            <ListItems onClick={() => handleLogOut()}>
              <IconsWrapper>
                <LogOutIcon />
              </IconsWrapper>
              <ListOptions>Sign out</ListOptions>
            </ListItems>
          </ListWrapper>
        )}
        {!CheckToken && (
          <ListWrapper>
            <ListItems onClick={handleLogin}>
              <IconsWrapper>
                <LoginIcon />
              </IconsWrapper>
              <ListOptions>Sign in</ListOptions>
            </ListItems>
          </ListWrapper>
        )}
      </MenuItemWrapper>
    </DrawerContainer>
  )

  useEffect(() => {
    const execute = async () => {
      const deviceId = await getOrCreateDeviceId()
      setDeviceId(deviceId)
    }

    execute()
  }, [])

  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (location.pathname === '/') {
        setSearch({ value: '' })
      }
    })

    // Clean up the listener on component unmount

    return () => {
      unlisten()
    }
  }, [history, setSearch])

  const updateSearchParams = (searchDataUpdate) => {
    let value = ''

    if (searchDataUpdate) {
      value = searchDataUpdate.value
    } else {
      value = search.value
    }

    const categoryName = query.get('c')

    const subCategoryName = query.get('sc')

    const params = new URLSearchParams({})

    if (locationData.pathname !== '/products') {
      history.push(`/products?s=${value}`)
    } else {
      if (value) {
        params.set('s', value)
      }

      if (categoryName) {
        params.set('c', categoryName)
      }

      if (subCategoryName) {
        params.set('sc', subCategoryName)
      }

      history.replace({ pathname: locationData.pathname, search: params.toString() })
    }
  }

  useEffect(() => {
    if (deviceId) fetchCartItems()
  }, [deviceId])

  const handlePincode = () => {
    setPincodeModal(true)
  }

  return (
    <>
      <NavbarWrapper>
        <NavbarUpperSection>
          <TextWrapper>
            <Text>An Initiative by Government of Himachal Pradesh</Text>
          </TextWrapper>
          <PincodeWrapper onClick={() => handlePincode()}>
            <LocationIcon />
            <PincodeText>
              {getPinCode || currentPincode ? (
                getPinCode || currentPincode
              ) : (
                <SelectPincodeText>Select location</SelectPincodeText>
              )}
            </PincodeText>
          </PincodeWrapper>
        </NavbarUpperSection>
        <NavbarContainer isHomepage={isHomepage}>
          <MobileWrapper>
            <MenuIcon onClick={toggleDrawer(true)} />
            <Drawer open={open} onClose={toggleDrawer(false)}>
              {drawerList}
            </Drawer>
            <MobileLogoWrapper onClick={() => history.push('/')}>
              <img src={HpLogo} alt="logo" />
            </MobileLogoWrapper>
          </MobileWrapper>
          <LogoWrapper onClick={() => history.push('/')}>
            <img src={HpLogo} alt="logo" />
          </LogoWrapper>
          <NavbarContentWrapper>
            {NavbarItems?.map((item, index) => {
              const isActive = location.pathname === item.link // Check if the current path matches the item's link

              return (
                <Link
                  key={index}
                  to={item?.link}
                  onClick={() => {
                    setSearchBar(false)
                  }}
                >
                  <MenuItem isActive={isActive}>{item.text}</MenuItem>
                </Link>
              )
            })}
          </NavbarContentWrapper>

          <IconContainer>
            {searchBar ? (
              <div>
                <TextField
                  className="search"
                  id="input-with-icon-textfield"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {isMobileView ? <MobileSearchIcon className="search-icon" /> : <SearchIcon />}
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  placeholder="Search here..."
                  value={search?.value || ''}
                  onKeyDown={(e) => {
                    if (e.keyCode == 13) {
                      e.preventDefault()
                      setSearchData(() => search)
                      setSearch('')
                      updateSearchParams()
                    }
                  }}
                  onChange={(e) => {
                    const searchValue = e.target.value
                    let searchDataUpdate = Object.assign({}, JSON.parse(JSON.stringify(search)))
                    searchDataUpdate.value = searchValue
                    setSearch(searchDataUpdate)
                    // generating context for search
                    const search_context = {
                      search: searchDataUpdate,
                      // location: searchedLocation,
                    }
                    AddCookie('search_context', JSON.stringify(search_context))
                  }}
                />
              </div>
            ) : (
              ''
            )}

            {isMobileView
              ? MobileIcons?.map((item, index) => {
                  return (
                    <IconWrapper key={index} onClick={item?.handleClick}>
                      {item?.icon}
                    </IconWrapper>
                  )
                })
              : Icons?.map((item, index) => {
                  return (
                    <IconWrapper key={index} onClick={item?.handleClick}>
                      {item?.icon}
                    </IconWrapper>
                  )
                })}
          </IconContainer>
        </NavbarContainer>
        {loginModal && (
          <ModalComponent open={loginModal} onClose={() => setLoginModal(false)}>
            <LoginModal onClose={() => setLoginModal(false)} />
          </ModalComponent>
        )}
        {pincodeModal && (
          <ModalComponent
            open={pincodeModal}
            onClose={() => setPincodeModal(false)}
            title={'Enter your pincode'}
            titleBg
          >
            <Pincode onClose={() => setPincodeModal(false)} />
          </ModalComponent>
        )}
        {logout && (
          <ModalComponent open={logout} onClose={() => setLogout(false)}>
            <ConfirmationModal
              onClose={() => setLogout(false)}
              title={'Are you sure want to sign out ?'}
              type={'logout'}
            />
          </ModalComponent>
        )}
      </NavbarWrapper>
    </>
  )
}

export default Navbar
