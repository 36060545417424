import { useState, useEffect, useMemo } from 'react'
import Pagination from '@mui/material/Pagination'
import useGet from 'hooks/useGet'
import ComplaintsCard from 'views/Complaints/ComplaintsCard'
import NoDataFound from 'views/EmptyData'
import { Container, NoDataWrapper, PaginationWrapper } from 'styles/ComplaintsCard'

const ActiveComplaints = ({ orderId }) => {
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalOrder: 0,
    postPerPage: 10,
    status: 'activeIssues',
  })
  const [totalOrdersCount, setTotalOrdersCount] = useState(0)

  const apiUrl = useMemo(() => {
    let url = `/issueApis/v1/getIssues?pageNumber=${pagination.currentPage}&limit=${pagination.postPerPage}&state=${pagination.status}`

    if (orderId) {
      url += `&search=${orderId}`
    }

    return url
  }, [pagination.currentPage, pagination.postPerPage, pagination.status, orderId])

  const { data: getIssues, refetch } = useGet('get-issues', apiUrl)

  useEffect(() => {
    if (getIssues?.totalOrder !== pagination.totalOrder) {
      setPagination((prev) => ({
        ...prev,
        totalOrder: getIssues?.totalOrder || 0,
      }))
      setTotalOrdersCount(getIssues?.totalOrder)
    }
  }, [getIssues?.totalOrder, pagination.totalOrder])

  const issueList = getIssues?.issues

  useEffect(() => {
    refetch()
  }, [orderId, refetch, pagination])

  return (
    <Container>
      {issueList?.length > 0 ? (
        issueList.map((issues, index) => (
          <div key={index}>
            <ComplaintsCard issueDetails={issues} />
          </div>
        ))
      ) : (
        <NoDataWrapper>
          <NoDataFound />
        </NoDataWrapper>
      )}
      <PaginationWrapper>
        <Pagination
          count={Math.ceil(totalOrdersCount / pagination.postPerPage)}
          shape="rounded"
          color="primary"
          page={pagination.currentPage}
          onChange={(event, currentPage) => {
            event
            setPagination((prevState) => ({ ...prevState, currentPage }))
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }}
        />
      </PaginationWrapper>
    </Container>
  )
}

export default ActiveComplaints
